import React from 'react';
import Modal from '.';
import { createRoot } from 'react-dom/client';
import * as styles from './index.module.less';

interface Item {
  label: string;
  value: string;
}

interface LinkDownloadModalProps {
  visible: boolean;
  list: Item[];
  onClose: () => void;
  width?: number | string;
}
export default function LinkDownloadModal({
  visible,
  list,
  width = 700,
  onClose,
}: LinkDownloadModalProps) {
  return (
    <Modal
      width={width}
      visible={visible}
      title="资料下载"
      top={100}
      onClose={onClose}
      footer={null}>
      <ul className={styles.linkDownloadModal}>
        {list.map((item) => {
          return (
            <li>
              <a href={item.value} target="_blank" download={item.label}>
                {item.label}
              </a>
            </li>
          );
        })}
      </ul>
    </Modal>
  );
}

export function linkDownloadModalShow(
  list: Item[],
  options: Omit<LinkDownloadModalProps, 'visible' | 'onClose' | 'list'> = {},
) {
  const div = document.createElement('div');

  const root = createRoot(div);
  root.render(<LinkDownloadModal visible list={list} onClose={closeModal} width={options.width} />);

  function closeModal() {
    root.unmount();
  }

  return closeModal;
}
